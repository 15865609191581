<template>
  <div class="container">
    <div v-if="submitted" class="alert alert-icon alert-success alert-dismissible">
      <button
        type="button"
        class="close"
        @click="submitted = false">
      </button>
      <i class="fe fe-check mr-2" aria-hidden="true"></i> Your changes have been
      saved successfully.
    </div>
    <div v-if="error" class="alert alert-icon alert-danger alert-dismissible">
      <button
        type="button"
        class="close"
        @click="error = false">
      </button>
      <i class="fe fe-alert-triangle mr-2" aria-hidden="true"></i>
      {{errorText}}
    </div>
    <div v-if="scheduledError" class="alert alert-icon alert-danger alert-dismissible">
      <button
        type="button"
        class="close"
        @click="scheduledError = false">
      </button>
      <i class="fe fe-alert-triangle mr-2" aria-hidden="true"></i>
      All recipes should be in status "Ready to be drafted" or "Published on
      Menu"
    </div>

    <dimmer :active="submitting" class="row-block">
      <div v-disable-all="!can(uiPermissions.MENU_SCHEDULE_VIEW)" class="page-header justify-content-center">
        <div class="card mb-0">
          <div class="card-header">
            <div class="card-title">Schedule menu</div>
            <div class="card-options">
              <a
                v-if="!edit"
                href="#"
                class="btn btn-link"
                @click.prevent="edit = true">Edit filter</a>
            </div>
          </div>
          <div class="card-body">
            <schedule-menu-filter
              :weekly-menus="weeklyMenus"
              :filter="filter"
              :edit="edit"
              @valid="(data) => fetchData(data)"/>
          </div>
        </div>
      </div>
    </dimmer>

    <template v-if="!edit">
      <dimmer :active="loading" class="row-block">
        <div class="row">
          <div class="col">
            <div class="d-flex py-4 justify-content-between align-items-center">
              <div>
                <button
                  v-if="showPopulateButton"
                  data-test="btn-populate"
                  class="btn btn-primary mr-2"
                  @click="handlePopulate">
                  Populate
                  <i v-if="loading" class="fe fe-loader spin-icon"></i>
                </button>
                <div v-if="can(uiPermissions.MENU_UPDATE) && !showPopulateButton" class="dropdown mr-2">
                  <button
                    type="button"
                    class="btn btn-primary dropdown-toggle"
                    data-test="btn-save-as"
                    data-toggle="dropdown">
                    <i class="fe fe-save"></i>
                    Save as
                  </button>
                  <div class="dropdown-menu">
                    <button class="dropdown-item cursor-pointer" @click.prevent="submitData('concept')">
                      Concept
                    </button>
                    <button
                      v-b-tooltip.right="'This draft sends an email to Procurement with the list of articles that are out of season in this menu'
                      "
                      class="dropdown-item cursor-pointer"
                      @click.prevent="submitData('draft')">
                      Draft
                    </button>
                    <button class="dropdown-item cursor-pointer" @click.prevent="submitData('scheduled')">
                      Scheduled
                    </button>
                  </div>
                </div>
                <button
                  v-if="can(uiPermissions.MENU_SCHEDULE_EXPORT) && week.startDate"
                  type="button"
                  class="btn btn-secondary"
                  @click="exportSchedule">
                  <i class="fe fe-download-cloud"></i>
                  Export
                </button>
              </div>

              <div>
                <h3 v-if="week.status === 'scheduled'" class="mb-0 text-success">
                  {{upperFirst(week.status)}}
                </h3>
                <h3 v-else-if="week.status === 'published'" class="mb-0 text-primary">
                  {{upperFirst(week.status)}}
                </h3>
                <h3 v-else class="mb-0 text-danger">
                  {{upperFirst(week.status)}}
                </h3>
              </div>

              <div>
                <div class="d-flex justify-content-end gap-8">
                  <div class="dropdown">
                    <button
                      v-if="shouldShowForecastTools"
                      type="button"
                      class="btn btn-success dropdown-toggle"
                      data-toggle="dropdown">
                      <i class="fe fe-bar-chart-2"></i>
                      Forecast week
                    </button>
                    <div class="dropdown-menu">
                      <button
                        v-if="shouldShowRequestForecast"
                        v-b-tooltip="tooltips.request"
                        class="dropdown-item cursor-pointer"
                        @click.prevent="forecastWeek">
                        <i class="fe fe-git-pull-request mr-2"></i>
                        Request forecast
                      </button>
                      <button
                        v-if="forecastJobIsProcessing"
                        v-b-tooltip="tooltips.recheck"
                        class="dropdown-item cursor-pointer"
                        @click.prevent="recheckWeekForecastResults">
                        <i class="fe fe-git-merge mr-2"></i>
                        Recheck
                      </button>
                    </div>
                  </div>
                  <button
                    v-if="can(uiPermissions.MENU_SIMULATE_CLONING_VIEW) &&
                      week.id &&
                      !showPopulateButton
                    "
                    type="button"
                    class="btn btn-secondary"
                    @click.prevent="weekHasIssues(week)">
                    <i
                      v-if="submitting"
                      class="fe fe-refresh-cw"
                      :class="{ 'spin-icon': submitting }"></i>
                    <i v-else class="fe fe-check-circle"></i>
                    Articles QC
                  </button>
                  <template>
                    <button
                      v-if="can(uiPermissions.MENU_SCHEDULE_SET_THEME) &&
                        addedTheme === null
                      "
                      type="button"
                      class="btn btn-secondary btn-icon"
                      :disabled="themeLoader"
                      @click.prevent="addTheme = true">
                      <i v-if="themeLoader" class="fe fe-loader spin-icon"></i>
                      <IconTheme v-else color="#495057"/>
                      Add theme
                    </button>
                    <button
                      v-else-if="can(uiPermissions.MENU_SCHEDULE_UNSET_THEME)"
                      type="button"
                      class="btn btn-secondary btn-icon text-danger"
                      :disabled="themeLoader"
                      @click.prevent="handleRemoveTheme">
                      <i v-if="themeLoader" class="fe fe-loader spin-icon"></i>
                      <IconTheme v-else color="#CD201F"/>
                      Remove theme
                    </button>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="d-flex justify-content-between">
              <div>
                <div v-if="!showPopulateButton" class="card board-card rounded-12 w-auto">
                  <label class="board-card-title">Preview mode</label>
                  <h-switch
                    :value="previewMode"
                    class="mt-1"
                    @input="(newValue) => (previewMode = newValue)"/>
                </div>
              </div>

              <div class="d-flex flex-column gap-8">
                <div class="px-2">
                  <div v-if="forecastJobIsProcessing" class="d-flex gap-4">
                    <div class="text-danger font-weight-bold">
                      Forecast results still processing,
                    </div>
                    <div class="text-info font-weight-bold">
                      <span v-if="maxRefreshCount > 0">Auto refresh in {{autoRefreshSeconds}}</span>
                      <span v-else>please
                        <span class="link-btn-recheck" @click.prevent="recheckWeekForecastResults">recheck</span>
                        the result</span>
                    </div>
                  </div>

                  <span v-if="forecastJobIsDone" class="text-success font-weight-bold d-block">
                    Forecast results are ready
                  </span>
                </div>

                <div class="d-flex gap-8">
                  <div
                    v-if="addedTheme"
                    class="card board-card"
                    :class="[
                      addedTheme.status === THEME_STATUS.draft
                        ? 'border-warning'
                        : 'border-success',
                    ]">
                    <a
                      :href="`/themes/${addedTheme.id}`"
                      target="_blank"
                      class="board-card-title"
                      rel="noreferrer noopener">
                      {{addedTheme.name}}
                    </a>
                    <div class="d-flex flex-column mt-1">
                      <div>{{themedRecipesAdded}} recipes added</div>
                      <div>{{themedRecipesAvailable}} recipes available</div>
                    </div>
                  </div>

                  <div class="card board-card">
                    <span class="board-card-title">Forecasted Food Cost</span>
                    <div class="board-card-value">
                      <span v-if="forecastJobIsDone" class="text-info">
                        {{foodCost}}
                      </span>
                      <i
                        v-else-if="forecastJobIsProcessing && maxRefreshCount > 0"
                        class="fe fe-loader mt-2 spin-icon"></i>
                      <span v-else class="text-muted"> NA </span>
                      <i v-if="loadingFoodCost" class="fe fe-loader mt-2 spin-icon"></i>
                    </div>
                  </div>

                  <div class="card board-card">
                    <span class="board-card-title">Menu Abandonment Rate</span>
                    <div class="board-card-value">
                      <i v-if="loadingAbandonmentRate" class="fe fe-loader mt-2 spin-icon"></i>
                      <span v-else-if="abandonmentRate" class="text-info">
                        {{numeral(abandonmentRate).format("0,0[.]00")}}
                      </span>
                      <span v-else class="text-muted"> NA </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <dimmer :active="loadingCuisineCount">
              <div class="card board-card w-100">
                <span class="board-card-title">Cuisines</span>
                <h-chip-list
                  readonly
                  secondary
                  class="font-weight-bold border-0 p-0"
                  :value="cuisineList">
                  <template #no-data-placeholder> No cuisines found </template>
                </h-chip-list>
              </div>
            </dimmer>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="recipe-tile-list">
              <div
                v-for="(recipe, index) in recipeListWithSwaps"
                :key="index"
                class="card recipe-tile"
                :class="[recipe.off_season_ingredients &&
                           recipe.off_season_ingredients.length
                           ? 'border border-2 border-danger pulse orange'
                           : 'border-0',
                         {'disable-card': disabledSlotsForPublishedWeek(recipe)}
                ]
                "
                :data-test="`recipe-tile-${index}`">
                <div v-if="recipe.id" class="recipe-card">
                  <div class="recipe-header">
                    <div v-if="!previewMode" class="actions">
                      <span
                        class="tag tag-secondary"
                        :class="{
                          'swap-highlight': recipe.shouldSwapHighlight,
                        }"
                        :data-test="`slot-number-indicator-${index}`">
                        <div v-if="recipe.shouldSwapHighlight && !recipe.is_default" class="d-flex">
                          <img
                            v-svg-inline
                            class="swap-arrow"
                            src="@/assets/svg/arrow-left-right.svg"
                            alt="recipe swap arrow"/>
                        </div>
                        R{{recipe.number}}
                      </span>
                    </div>

                    <div v-if="!previewMode" class="status d-flex flex-column text-uppercase align-items-end">
                      <div class="tag tag-secondary bbr-0">
                        {{recipe.serialNumber}}
                      </div>
                      <div class="tag mt-1 br-0" :class="statusColor(recipe.status)">
                        {{statusName(recipe.status)}}
                      </div>
                    </div>

                    <div v-if="recipe.photo" class="w-100 h-100">
                      <img
                        :src="getTransformationUrl(recipe.photo, {
                          height: 456,
                          width: 'auto',
                          crop: 'fill',
                        })
                        "
                        class="recipe-img"
                        :alt="recipe.cardName1"/>
                    </div>
                    <div
                      v-else
                      class="d-flex justify-content-center align-items-center bg-gray-lighter empty-card-header"
                      style="min-height: 200px">
                      <template
                        v-for="scope in [
                          { details: getSlotDetailByNumber(recipe.number) },
                        ]">
                        <div
                          v-if="scope.details.protein.id"
                          :key="scope.details.protein.id"
                          class="d-flex flex-column align-items-center">
                          <h3 class="lead font-weight-semibold text-uppercase">
                            {{scope.details.protein.name}}
                            <span v-if="scope.details.weeklyClassic">
                              + Weekly Classic</span>
                          </h3>
                          <div class="d-flex gap-8">
                            <img
                              v-b-tooltip="scope.details.protein.name"
                              class="img-shadow"
                              :src="getIconAsset(scope.details.protein.name, true)"
                              :alt="scope.details.protein.name"/>
                            <img
                              v-if="scope.details.weeklyClassic"
                              v-b-tooltip="'Weekly Classic'"
                              src="@/assets/svg/weekly_classic_frame.svg"/>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                  <div class="card-body d-flex flex-column px-0 py-0 w-100">
                    <div class="px-4 recipe-details" :class="{ 'recipe-details-preview`': previewMode }">
                      <div class="title">
                        <div class="d-flex flex-row justify-content-between align-items-center">
                          <div class="d-flex align-items-center gap-6">
                            <i
                              v-if="recipe.by_ml"
                              :data-test="`by-ml-icon-${recipe.id}`"
                              class="fe fe-cpu text-muted"></i>
                            <i
                              v-else
                              :data-test="`by-user-icon-${recipe.id}`"
                              class="fe fe-user text-muted"></i>
                            <img
                              v-b-tooltip="recipe.mainProtein.name"
                              :src="getIconAsset(recipe.mainProtein.name + '_full')"
                              :alt="recipe.mainProtein.name"/>
                          </div>
                          <div
                            v-if="recipe.off_season_ingredients.length"
                            class="d-flex justify-content-center align-items-center pulse orange rounded-full"
                            style="width: 20px; height: 20px"
                            :data-test="`off-season-ingredient-icon-${index}`">
                            <i
                              v-b-tooltip="{
                                title: getOutOfSeasonIngredients(recipe),
                                html: true,
                              }"
                              class="fe fe-alert-circle text-danger"
                              style="font-size: 24px"></i>
                          </div>
                        </div>
                        <div>
                          <h6>
                            <a
                              :href="`/recipes/${recipe.id}`"
                              target="_blank"
                              rel="noreferrer noopener">{{recipe.cardName1}}</a>
                          </h6>
                          <h5>{{recipe.cardName2}}</h5>
                        </div>
                      </div>

                      <div class="d-flex flex-column gap-12">
                        <div class="features d-flex">
                          <div v-if="recipe.weeklyClassic" class="d-flex separator">
                            <img
                              v-b-tooltip="'Weekly Classic'"
                              src="@/assets/svg/weekly_classic.svg"
                              alt="Weekly Classic"
                              class="feature-icon"/>
                          </div>

                          <template v-for="feature in recipe.features">
                            <div
                              v-if="getIconAsset(feature.name) &&
                                !feature.name.startsWith('Veg')
                              "
                              :key="feature.id"
                              class="d-flex separator">
                              <img
                                v-b-tooltip="feature.name"
                                :src="getIconAsset(feature.name)"
                                :alt="feature.name"
                                :class="LONG_FEATURES.includes(feature.name)
                                  ? 'icon-long'
                                  : ''
                                "
                                class="feature-icon"/>
                            </div>
                          </template>

                          <template v-for="feature in recipe.dynamic_features">
                            <div
                              v-if="getIconAsset(feature)"
                              :key="feature"
                              class="d-flex separator">
                              <img
                                v-b-tooltip="statusName(feature)"
                                :src="getIconAsset(feature)"
                                :alt="statusName(feature)"
                                class="feature-icon"/>
                            </div>
                          </template>

                          <div
                            v-if="(recipe.shouldSwapHighlight && !recipe.is_default) ||
                              (recipe.swappable_recipes_count && recipe.is_default)
                            "
                            class="d-flex separator">
                            <img
                              v-b-tooltip="'Swap Available'"
                              src="@/assets/svg/recipe_swap.svg"
                              alt="Recipe Swap"
                              class="feature-icon"/>
                          </div>
                        </div>

                        <div class="d-flex recipe-info">
                          <template v-for="(item, i) in recipeDetails">
                            <div
                              v-if="(!['Cooking Time', 'Calories'].includes(item) &&
                                !previewMode) ||
                                ['Cooking Time', 'Calories'].includes(item)
                              "
                              :key="i"
                              class="d-flex separator">
                              <div v-b-tooltip="item" class="d-flex align-items-center gap-2">
                                <img :src="getRecipeDetails(recipe, item).icon" class="feature-icon"/>
                                <span>
                                  {{getRecipeDetails(recipe, item).value}}
                                  <span v-if="previewMode && item === 'Calories'">cals</span>
                                </span>
                              </div>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>

                    <div v-if="!previewMode" class="px-4 py-3 d-flex flex-column gap-2 border-top">
                      <small>Cost per serving:
                        <strong>AED {{numeral(recipe.cost || 0).format("0,0.00")}}</strong></small>
                      <small v-if ="hasSurcharge(recipe)" class="text-danger">Surcharge per people:
                        <strong>AED {{displaySurcharges(recipe)}}</strong>
                      </small>
                      <small>Frequency: <b>{{recipe.frequency}}</b></small>
                      <small>Historical uptake:
                        <strong>{{numeral(recipe.historicalUptakes).format("0,0[.]00%")}}</strong></small>
                      <small>Historical rating:
                        <strong>{{numeral(recipe.avgRating).format("0,0[.]00")}} / 5</strong></small>
                      <small v-if="hasUptakes">Forecasted uptake:
                        <strong>{{getRecipeUptake(recipe.id)}}</strong></small>
                    </div>

                    <div v-if="!previewMode" class="d-flex flex-column justify-content-end">
                      <!-- #region Swaps indicator section -->
                      <div
                        class="small border-top py-3 px-4 swap-indicator"
                        :class="recipe.swappable_recipes_count && recipe.is_default
                          ? 'visible'
                          : 'invisible'
                        ">
                        <div
                          v-if="availableSwapCount(recipe)"
                          class="swap-action"
                          :data-test="`swap-action-button-${index}`"
                          @click="handleAddSwapShow(recipe, recipe.number)">
                          <i class="fe fe-plus plus-icon"></i>
                          <p class="p-text available-swap-text">
                            {{availableSwapCount(recipe)}}
                          </p>
                        </div>
                        <div v-else>
                          <p class="p-text text-muted">All swaps added</p>
                        </div>
                        <div v-if="recipe.swapCount" class="swap-label">
                          <p class="p-text">{{recipe.swapCount}} added</p>
                        </div>
                      </div>
                      <!-- #endregion Swaps indicator section -->

                      <!-- #region theme added indicator section -->
                      <div
                        class="border-top py-3 px-4 font-weight-semibold"
                        :class="addedTheme && addedTheme.id === recipe.theme_id
                          ? 'visible'
                          : 'invisible'
                        "
                        style="background: #fef0cc">
                        Themed recipe
                      </div>
                      <!-- #endregion theme added indicator section -->

                      <div v-if="!previewMode" class="px-4 py-3 border-top">
                        <div class="text-muted font-weight-lightbold d-flex align-items-center gap-4">
                          <i v-b-tooltip="'In concept menu previous to the current week'" class="fe fe-alert-circle"></i> In concept menu:
                          <span class="font-weight-semibold">
                            {{lastConceptMenuStartDate(recipe)}}
                          </span>
                        </div>
                      </div>

                      <div class="card-footer px-4 d-flex justify-content-between align-items-center">
                        <div class="text-muted font-weight-lightbold">
                          Last published:
                          <span class="font-weight-semibold">
                            {{recipe.lastPublished ? moment(recipe.lastPublished).format("MMM DD, YYYY") : 'N/A'}}
                          </span>
                        </div>
                        <button class="btn btn-danger btn-delete" @click="handleDelete(recipe)">
                          <i class="fe fe-trash"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div v-if="previewMode" class="px-4 recipe-footer">
                    <div class="add-button text-muted text-uppercase">Add</div>
                  </div>
                </div>
                <div
                  v-else
                  class="card empty-card border-0 h-100"
                  :style="'min-height: 550px'">
                  <div class="actions">
                    <span class="tag tag-secondary">R{{recipe.slotNumber}}</span>
                  </div>
                  <div
                    class="d-flex flex-column justify-content-center align-items-center bg-gray-lighter recipe-header">
                    <h3 class="lead font-weight-semibold text-uppercase recipe-header-text">
                      <span v-if="recipe.details.protein">
                        {{recipe.details.protein.name}}
                      </span>
                      <span v-if="recipe.details.weeklyClassic">
                        Weekly Classic
                      </span>
                    </h3>
                    <div class="d-flex gap-8">
                      <img
                        v-b-tooltip="recipe.details.protein.name"
                        class="drop-shadow"
                        :src="getIconAsset(recipe.details.protein.name, true)"
                        :alt="recipe.details.protein.name"/>
                      <img
                        v-if="recipe.details.weeklyClassic"
                        v-b-tooltip="'Weekly Classic'"
                        src="@/assets/svg/weekly_classic_frame.svg"/>
                    </div>
                  </div>
                  <div
                    v-if="can(uiPermissions.MENU_SCHEDULE_UPDATE) && week.status"
                    class="card-body d-flex align-items-center justify-content-center flex-column">
                    <button class="btn btn-success" @click="addRecipeModal(recipe.slotNumber)">
                      <i class="fe fe-plus"></i> Add recipe
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </dimmer>
    </template>

    <b-modal
      v-if="selectedSlot"
      v-model="recipeAddShowModal"
      :scrollable="true"
      :title="`Add recipe for R${selectedSlot.number} - ${slotText(
        selectedSlot.number
      )}`"
      hide-footer
      size="lg"
      modal-class="modal-add-recipe-form"
      id="modal-add-recipe-form">
      <template slot="modal-header-close">
        <wbr/>
      </template>

      <add-recipe-form
        v-model="selectedSlot"
        :start-date="week.startDate"
        :theme-added="addedTheme !== null"
        @select="(recipe) => addRecipe(recipe)">
      </add-recipe-form>
    </b-modal>

    <schedule-menu-swap-list
      :recipe="selectedRecipe"
      :is-show="isAddSwapShow"
      :start-date="week.startDate"
      :linked-swaps="swapsLinkedToRecipe"
      @show="(value) => (isAddSwapShow = value)"
      @select="handleAddSwap"/>

    <simulate-week :show.sync="showSimulateModal" :ingredients="missing"/>

    <b-modal
      v-model="addTheme"
      :scrollable="true"
      title="Add a theme to this week"
      modal-class="modal-add-theme"
      hide-footer
      id="modal-add-theme">
      <template slot="modal-header-close">
        <wbr/>
      </template>
      <add-theme-list @onSelected="handleThemeSelected"/>
    </b-modal>

    <b-modal
      v-if="addedTheme"
      v-model="showThemeNotReady"
      centered
      modal-class="modal-theme-not-ready">
      <!-- header content -->
      <template slot="modal-header">
        <img v-b-tooltip="'Weekly Classic'" src="@/assets/svg/warning.svg"/>
        <button
          type="button"
          aria-label="Close"
          class="close btn btn-link"
          @click="showThemeNotReady = false"></button>
      </template>

      <!-- body content -->
      <div class="text-center">
        <h3>Theme is not ready to publish.</h3>
        <p>
          Please fill in the missing text and/or images to the selected theme.
        </p>
      </div>

      <!-- footer content -->
      <template slot="modal-footer">
        <button
          type="button"
          class="btn"
          @click="showThemeNotReady = false">
          Cancel
        </button>
        <!-- using link tag to open in new page -->
        <a
          :href="`/themes/${addedTheme.id}`"
          target="_blank"
          class="btn btn-primary text-white"
          rel="noreferrer noopener"
          @click="showThemeNotReady = false">
          Go to theme page
        </a>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Proteins from '@/assets/enums/Proteins';
import SimulateWeek from '@/components/SimulateWeek';
import IconTheme from '@/components/icons/IconTheme.vue';
import HChipList from '@/components/ui/HChipList';
import HSwitch from '@/components/ui/HSwitch';
import ScheduleMenuSwapList from '@/components/view/schedule-menu/schedule-menu-swap-list';
import {RECIPE_AVAILABILITY} from '@/constants';
import download from '@/mixins/download';
import menuUptakeForecast from '@/mixins/menuUptakeForecast';
import simulateWeek from '@/mixins/simulateWeek';
import {weeklyMenus, weeklyMenusBasic} from '@/services';
import AddRecipeForm from '@/views/scheduleMenu/AddRecipeForm';
import AddThemeList from '@/views/scheduleMenu/AddThemeList';
import ScheduleMenuFilter from '@/views/scheduleMenu/filters/ScheduleMenuFilter';
import {thursdayBasedWeek} from '@hellochef/shared-js/helpers';
import submitting from '@hellochef/shared-js/mixins/submitting';
import {captureException} from '@sentry/vue';
import {startCase} from 'lodash';
import moment from 'moment-timezone';
import {mapActions} from 'vuex';
import numeral from 'numeral';

const FISH_RECIPES_SLOT_NUMBERS = [1, 2, 11, 21, 22, 30, 31];
const CHICKEN_RECIPES_SLOT_NUMBERS = [3, 4, 5, 9, 12, 13, 23, 27, 28, 29];
const MEAT_RECIPES_SLOT_NUMBERS = [6, 7, 10, 14, 24, 25, 26];
const VEG_RECIPES_SLOT_NUMBERS = [8, 15, 32, 33];
const VEGAN_RECIPES_SLOT_NUMBERS = [16, 17, 18, 19, 34];
const WEEKLY_CLASSIC_SLOT_NUMBERS = [13, 14];
const NEW_WEEKLY_CLASSIC_SLOTS = [10, 12];
const GOURMET_SLOT_NUMBERS = [20];
const FOUR_WEEKLY_CLASSIC_MENU_START_DATE = '2024-07-04';

const LONG_FEATURES = ['Quick Prep'];

const THEME_STATUS = {
  draft: 'draft',
  ready: 'ready',
};

export default {
  name: 'ScheduleMenu',
  components: {
    ScheduleMenuFilter,
    AddRecipeForm,
    SimulateWeek,
    ScheduleMenuSwapList,
    HSwitch,
    AddThemeList,
    IconTheme,
    HChipList,
  },
  mixins: [download, submitting, simulateWeek, menuUptakeForecast],
  data() {
    return {
      LONG_FEATURES,
      THEME_STATUS,
      edit: true,
      filter: {
        weeklyMenu: null,
      },
      recipeAddShowModal: false,
      selectedSlot: null,
      submitting: false,
      scheduledError: false,
      week: {
        recipes: Array(this.MENU_SIZE).fill({}),
        startDate: '',
        status: '',
      },
      selectedRecipe: {},
      weeklyMenus: [],
      Proteins,
      isAddSwapShow: false,
      swapsLinkedToRecipe: [],
      errorText: '',
      previewMode: false,
      icons: [
        {
          name: 'Fish',
          icon: 'fish.svg',
        },
        {
          name: 'Fish_full',
          icon: 'fish_full.svg',
        },
        {
          name: 'Vegetarian',
          icon: 'vegetarian.svg',
        },
        {
          name: 'Vegetarian_full',
          icon: 'vegetarian_full.svg',
        },
        {
          name: 'Vegan',
          icon: 'vegan.svg',
        },
        {
          name: 'Vegan_full',
          icon: 'vegan_full.svg',
        },
        {
          name: 'Meat',
          icon: 'meat.svg',
        },
        {
          name: 'Meat_full',
          icon: 'meat_full.svg',
        },
        {
          name: 'Poultry',
          icon: 'poultry.svg',
        },
        {
          name: 'Poultry_full',
          icon: 'poultry_full.svg',
        },
        {
          name: 'Calorie smart',
          icon: 'calorie_smart.svg',
        },
        {
          name: 'Family friendly',
          icon: 'family_friendly.svg',
        },
        {
          name: 'Low carb',
          icon: 'low_carbs.svg',
        },
        {
          name: 'Gourmet',
          icon: 'gourmet.svg',
        },
        {
          name: 'Gourmet_frame',
          icon: 'gourmet_frame.svg',
        },
        {
          name: 'Chef\'s choice',
          icon: 'chefs_choice.svg',
        },
        {
          name: 'Quick Prep',
          icon: 'quick_prep_long.svg',
        },
        {
          name: 'Tips for Kids',
          icon: 'kids.svg',
        },
        {
          name: 'Weekly Classic',
          icon: 'weekly_classic.svg',
        },
        {
          name: 'Air Fryer',
          icon: 'air_fryer.svg',
        },
        {
          name: 'Express',
          icon: 'express.svg',
        },
        {
          name: 'Time',
          icon: 'time.svg',
        },
        {
          name: 'Calories',
          icon: 'calories.svg',
        },
        {
          name: 'Protein',
          icon: 'protein.svg',
        },
        {
          name: 'Carbs',
          icon: 'carbs.svg',
        },
        {
          name: 'Fat',
          icon: 'fat.svg',
        },
      ],
      recipeDetails: ['Cooking Time', 'Calories', 'Protein', 'Carbs', 'Fat'],
      addTheme: false,
      themeLoader: false,
      addedTheme: null,
      showThemeNotReady: false,
      deletedRecipes: [],
      addedRecipes: [],
      loading: false,
      cuisineList: [],
      loadingCuisineCount: false,
    };
  },
  computed: {

    displaySurcharges() {
      return recipe => {
        if (!this.hasSurcharge(recipe)) return '';

        const surcharges = [recipe.gourmetSurchargeFor2, recipe.gourmetSurchargeFor3, recipe.gourmetSurchargeFor4].map(surcharge => numeral(surcharge || 0).format('0,0[.]00'));
        return surcharges.join(' | ');
      };
    },

    hasSurcharge() {
      return recipe => {
        const surcharges = [recipe.gourmetSurchargeFor2, recipe.gourmetSurchargeFor3, recipe.gourmetSurchargeFor4];
        return surcharges.some(surcharge => surcharge > 0);
      };
    },

    // FUTURE TODO: Make a pluralize helper
    /** Generates the swap indication text to show */
    availableSwapCount() {
      return recipe =>
        recipe.remainingSwapCount &&
        `${recipe.remainingSwapCount} swap${recipe.remainingSwapCount > 1 ? 's' : ''
        } available`;
    },
    /**
     * Generates the main list on which we iterate to show the recipes
     * @description
     * 1. Virtually connects the swaps with the primary recipe to indicate the linkage (denoted by shouldSwapHighlight).
     * 2. Calculates and assigns the slot number for the unselected recipes.
     * 3. Calculates the swap count and remaining swap count for the swap indications.
     */
    recipeListWithSwaps() {
      // Keeps track of the empty recipe placeholder slots
      let slotCounter = 0;
      let mainSwapRecipeIndex = 0;

      const recipesLength = this.week.recipes.length;

      for (let index = 0; index < recipesLength; index++) {
        const currentRecipe = this.week.recipes[index];

        if (currentRecipe.is_default) {
          slotCounter += 1;
          mainSwapRecipeIndex = index;

          // For the swap indicators
          currentRecipe.remainingSwapCount = currentRecipe.swappable_recipes_count;
          currentRecipe.swapCount = 0;
          currentRecipe.shouldSwapHighlight = false;
        }
        else {
          currentRecipe.shouldSwapHighlight = true;

          const mainSwapRecipe = this.week.recipes[mainSwapRecipeIndex];
          mainSwapRecipe.shouldSwapHighlight = true;
          mainSwapRecipe.swapCount += 1;
          mainSwapRecipe.remainingSwapCount -= 1;
        }

        currentRecipe.slotNumber = slotCounter;

        // add more details, protein and weekly classic flag
        currentRecipe.details = this.getSlotDetailByNumber(slotCounter);
      }

      return this.week.recipes;
    },
    themedRecipesAdded() {
      return this.addedTheme && this.recipeListWithSwaps.filter(item => item.theme_id === this.addedTheme.id).length;
    },
    themedRecipesAvailable() {
      return this.addedTheme && (this.addedTheme.recipes_count - this.themedRecipesAdded);
    },
    showPopulateButton() {
      return !this.week.status || !this.week.recipes.some(it => 'id' in it);
    },
    hasFourWeeklyClassicMenu() {
      return new Date(this.filter.weeklyMenu?.startDate) >= new Date(FOUR_WEEKLY_CLASSIC_MENU_START_DATE);
    },
    weeklyClassicSlots() {
      if (this.hasFourWeeklyClassicMenu) {
        return [...WEEKLY_CLASSIC_SLOT_NUMBERS, ...NEW_WEEKLY_CLASSIC_SLOTS];
      }
      return WEEKLY_CLASSIC_SLOT_NUMBERS;
    },
    chickenRecipesSlots() {
      if (this.hasFourWeeklyClassicMenu) {
        return CHICKEN_RECIPES_SLOT_NUMBERS.filter(slot => !NEW_WEEKLY_CLASSIC_SLOTS.includes(slot));
      }
      return CHICKEN_RECIPES_SLOT_NUMBERS;
    },
    meatRecipesSlots() {
      if (this.hasFourWeeklyClassicMenu) {
        return MEAT_RECIPES_SLOT_NUMBERS.filter(slot => !NEW_WEEKLY_CLASSIC_SLOTS.includes(slot));
      }
      return MEAT_RECIPES_SLOT_NUMBERS;
    },
  },
  async created() {
    await this.fetchWeeklyMenus();
  },
  methods: {
    ...mapActions('events', ['track']),
    async fetchWeeklyMenus() {
      const {items} = await weeklyMenusBasic
        .getByParameters({
          column: 'id',
          direction: 'desc',
          limit: 25,
        });
      this.weeklyMenus = items;
      // set default earliest week available
      this.filter.weeklyMenu = this.weeklyMenus?.[0] || null;
      if (this.$route.query.date) {
        const findWeeklyMenu = this.weeklyMenus.find(item => item.startDate === this.$route.query.date);
        if (findWeeklyMenu) {
          this.filter.weeklyMenu = findWeeklyMenu;
          this.fetchData();
        }
      }
    },
    statusName(name) {
      return startCase(name);
    },
    statusColor(name) {
      switch (name) {
      case 'ready-to-be-drafter':
        return 'tag-secondary';
      case 'published-on-menu':
        return 'tag-success';
      default:
        return '';
      }
    },
    addRecipe(item) {
      // Prevent adding recipe if it is already a swap recipe
      if (this.week.recipes.find(it => it.id === item.id)) {
        alert('This recipe is already present in the list');
        return;
      }

      item.is_default = true;
      item.by_ml = false;

      const indexToAdd = this.week.recipes.findIndex(
        recipe => recipe.slotNumber === this.selectedSlot.number,
      );

      const recipeToAdd = Object.assign(item, {
        number: this.selectedSlot.number,
      });

      this.week.recipes.splice(indexToAdd, 1, recipeToAdd);

      this.recipeAddShowModal = false;

      // added recipes for segment
      if (this.addedRecipes.find(item => item.id === recipeToAdd.id)) return;
      this.addedRecipes.push(recipeToAdd);
    },
    addRecipeModal(number) {
      const {protein, weeklyClassic} = this.getSlotDetailByNumber(number);

      const params = {
        number,
      };
      this.selectedSlot = weeklyClassic
        ? {
          ...params,
          weeklyClassic,
        }
        : params;

      if (protein.name === 'Gourmet') {
        this.selectedSlot.feature = 'Gourmet';
      }
      else {
        this.selectedSlot.protein = protein.id;
      }

      this.recipeAddShowModal = true;
    },
    exportSchedule() {
      this.submitIt(async () => {
        this.download(
          await weeklyMenus.exportScheduleByDate({
            menuId: this.week?.id,
            recipeIds: this.formattedRecipeIds,
            endDate: moment(this.week.startDate)
              .add(7, 'days')
              .format('YYYY-MM-DD'),
            startDate: moment(this.week.startDate).format('YYYY-MM-DD'),
          }),
        );
      });
    },
    async fetchData() {
      if (this.filter.weeklyMenu === 'create') { return this.createNewConceptWeek(); }

      // reset all the menu forecast state
      this.resetAllMenuForecastState();

      this.$router.push({
        query: {
          date: this.filter.weeklyMenu.startDate,
        },
      });

      this.submitting = true;
      try {
        const fetch = await weeklyMenus.fetch({
          startDate: this.filter.weeklyMenu.startDate,
          withOffSeasonIngredients: true,
        });

        this.generateRecipeListAndForecast(fetch.weeklyMenu);
      }
      catch (e) {
        console.error(e);
      }
      finally {
        this.edit = false;
        this.submitting = false;
      }

      this.getCuisineList();
    },
    generateRecipeListAndForecast(item) {
      // fill in the recipes to be listed in the menu
      const week = Object.assign({}, item, {
        recipes: Array(this.MENU_SIZE)
          .fill(null)
          .map((val, index) => {
            if (
              item.recipes.find(
                item => index + 1 === item.number,
              )
            ) {
              const matchingRecipes = item.recipes.filter(
                item => index + 1 === item.number,
              );
              return [
                ...matchingRecipes.filter(item => item.is_default),
                ...matchingRecipes.filter(item => !item.is_default),
              ];
            }
            return {is_default: true};
          })
          .flat(),
      });
      this.week = week;

      // assign the theme or null
      this.addedTheme = this.week.theme || null;
      this.checkWeekForecastResults();
    },
    createNewConceptWeek() {
      const startDate = thursdayBasedWeek(
        moment(this.weeklyMenus?.[0]?.startDate).add(1, 'week'),
      ).format('YYYY-MM-DD');
      const week = {
        recipes: Array.from({length: this.MENU_SIZE}, () => ({is_default: true})),
        startDate,
        status: '',
      };

      this.week = week;
      this.filter.weeklyMenu = week;
      this.edit = false;

      // reset all the menu forecast state on create new concept week
      this.resetAllMenuForecastState();
    },
    getSlotDetailByNumber(slotNumber) {
      let protein = '';
      let weeklyClassic = 0;

      if (this.weeklyClassicSlots.includes(slotNumber)) {
        weeklyClassic = 1;
      }

      if (FISH_RECIPES_SLOT_NUMBERS.includes(slotNumber)) {
        protein = Proteins.find(p => p.name === 'Fish');
      }
      else if (this.chickenRecipesSlots.includes(slotNumber)) {
        protein = Proteins.find(p => p.name === 'Poultry');
      }
      else if (this.meatRecipesSlots.includes(slotNumber)) {
        protein = Proteins.find(p => p.name === 'Meat');
      }
      else if (VEG_RECIPES_SLOT_NUMBERS.includes(slotNumber)) {
        protein = Proteins.find(p => p.name === 'Vegetarian');
      }
      else if (VEGAN_RECIPES_SLOT_NUMBERS.includes(slotNumber)) {
        protein = Proteins.find(p => p.name === 'Vegan');
      }
      else if (GOURMET_SLOT_NUMBERS.includes(slotNumber)) {
        protein = {
          id: 'Gourmet',
          name: 'Gourmet',
        };
      }

      return {protein, weeklyClassic};
    },
    handleDelete(recipe) {
      // removes the swap only
      if (!recipe.is_default) {
        this.week.recipes = this.week.recipes.filter(
          it => it.id !== recipe.id,
        );
        return;
      }

      const deletionIndex = this.week.recipes.findIndex(
        it => it.id === recipe.id,
      );
      // removes the swaps related to the main recipe
      this.week.recipes = this.week.recipes.filter(
        it => it.number !== recipe.number,
      );
      // removes the main recipe and replaces it with a slot placeholder
      this.week.recipes.splice(deletionIndex, 0, {is_default: true});

      // deleted recipes for segment
      if (this.deletedRecipes.find(item => item.id === recipe.id)) return;
      this.deletedRecipes.push(recipe);
    },
    async handlePopulate() {
      try {
        this.loading = true;
        const startDate = moment(this.week.startDate);
        const result = await weeklyMenus.populate(
          startDate.format('YYYY-MM-DD'),
        );

        if (result.schedule.recipes.length === 0) {
          this.$toasted.error('No recipes populated for this week');
        }

        this.generateRecipeListAndForecast(result.schedule);

        // ignore if the week is already present in the list
        if (this.weeklyMenus.find(it => it.id === this.week.id)) {
          return;
        }

        this.weeklyMenus.unshift(this.week);
        this.filter.weeklyMenu = this.week;
      }
      catch (error) {
        console.error(error);
        if (error?.response?.status === 520) {
          this.$toasted.error(
            'Error while populating the week, ' + error.response.data.message,
          );
          return;
        }
        captureException(error);
      }
      finally {
        this.loading = false;
      }
    },
    slotText(number) {
      const {protein} = this.getSlotDetailByNumber(number);

      let recipeText = protein.name || '';

      if (this.weeklyClassicSlots.includes(number)) {
        recipeText += recipeText ? ' + Weekly Classic' : 'Weekly Classic';
      }

      return recipeText;
    },
    submitData(status) {
      this.submitting = true;
      let canBeProceed = true;

      // confirm if the week should be unpublished
      if (this.week.status === 'published') {
        canBeProceed = confirm('Are you sure you want to unpublish this week?');

        if (!canBeProceed) {
          this.submitting = false;
          return;
        }
      }

      if (status === 'scheduled') {
        // check if all the recipes are ready to be scheduled
        canBeProceed = this.week.recipes.every(
          it =>
            it.status === 'ready-to-be-drafted' ||
            it.status === 'published-on-menu',
        );
      }

      if (!canBeProceed) {
        this.scheduledError = true;
        this.submitting = false;
        return;
      }

      // check if any theme is added and it is not draft
      if (
        status === 'scheduled' &&
        this.addedTheme &&
        this.addedTheme.status === THEME_STATUS.draft
      ) {
        this.showThemeNotReady = true;
        this.submitting = false;
        return;
      }

      const payload = Object.assign({}, this.week, {
        recipes: {},
        startDate: moment(this.week.startDate).format('YYYY-MM-DD'),
        status: status,
      });

      payload.recipes = this.week.recipes
        .filter(it => !!it.id)
        .map(it => ({
          recipe_id: it.id,
          number: it.number,
          is_default: it.is_default,
          by_ml: it.by_ml,
          availability: RECIPE_AVAILABILITY.default,
        }));

      this.submitIt(async () => {
        try {
          const result = await weeklyMenus.saveOrUpdate(payload);
          this.week = Object.assign(this.week, {
            status: result.item.status,
          });
          // fetch the data again to get off season ingredients in a recipe
          this.fetchData();
        }
        catch (error) {
          this.errorText = this.generateErrorMessage(error);
          throw error;
        }
      });

      this.resetAllMenuForecastState();

      // track the added and removed recipes on save
      this.addSegmentEvents();
    },
    handleAddSwap(recipe) {
      // Prevent adding swap recipe if it is already a main recipe
      if (this.week.recipes.find(it => it.id === recipe.id)) {
        alert('This recipe is already in the list');
        return;
      }

      // Swapped recipes are not allowed to have the swap actions
      recipe.swappable_recipes_count = 0;

      const selectedRecipeIndex = this.week.recipes.findIndex(
        it => it.id === this.selectedRecipe.id,
      );
      recipe.number = this.week.recipes[selectedRecipeIndex].number;
      recipe.is_default = false;

      this.week.recipes.splice(selectedRecipeIndex + 1, 0, recipe);

      this.selectedRecipe = {};
      this.isAddSwapShow = false;
    },
    handleAddSwapShow(recipe, number) {
      const {weeklyClassic} = this.getSlotDetailByNumber(number);
      this.selectedRecipe = Object.assign(
        {},
        recipe,
        weeklyClassic,
      );

      this.swapsLinkedToRecipe = this.week.recipes
        .filter(it => it.id !== recipe.id && it.number === recipe.number)
        .map(it => it.id);

      this.isAddSwapShow = true;
    },
    getIconAsset(name, slot) {
      if (!name) return null;

      // get the main protein name by slot number
      if (slot) {
        name = name === 'Gourmet' ? 'Gourmet_frame' : name;
      }
      const icon = this.icons.find(
        icon => icon.name?.toLowerCase() === name?.toLowerCase(),
      );

      if (icon) {
        return require(`@/assets/svg/${icon.icon}`);
      }
    },
    getRecipeDetails(recipe, name) {
      switch (name) {
      case 'Cooking Time':
        return {
          icon: this.getIconAsset('time'),
          value: recipe.cooking_time,
        };
      case 'Calories':
      case 'Protein':
      case 'Carbs':
      case 'Fat':
        return {
          icon: this.getIconAsset(name),
          value: parseFloat(
            recipe.nutritions.find(item => item.name === name).value || 0,
          ).toFixed(0),
        };
      case 'Cost Per Serving':
        return {
          icon: this.getIconAsset('time'),
          value: this.numeral(recipe.cost || 0).format('0,0.00'),
        };
      case 'Frequency':
        return {
          icon: this.getIconAsset('time'),
          value: recipe.frequency,
        };
      case 'Historical Uptakes':
        return {
          icon: this.getIconAsset('time'),
          value: this.numeral(recipe.historicalUptakes || 0).format(
            '0,0[.]00%',
          ),
        };
      case 'Historical Rating':
        return {
          icon: this.getIconAsset('time'),
          value: this.numeral(recipe.avgRating || 0).format('0,0[.]00'),
        };
      case 'Forcasted Uptake':
        return {
          icon: this.getIconAsset('time'),
          value: this.getRecipeUptake(recipe.id),
        };
      }
    },
    // TODO move to utils/helpers
    generateErrorMessage(error) {
      try {
        const errorItems = Object.values(error.response.data.errors);
        return errorItems.reduce((acc, item) => {
          // remove duplicates
          if (acc.includes(item)) {
            return acc;
          }

          acc += item.join(' ');
          return acc + '\n';
        }, '');
      }
      catch (e) {
        console.error(error);
        return 'Something went wrong. Please try again.';
      }
    },
    getOutOfSeasonIngredients(recipe) {
      let response = '';
      recipe.off_season_ingredients.forEach(it => {
        response += `<li>${it}</li>`;
      });
      return `<div class="text-left d-flex flex-column gap-4">
        Ingredients listed below are out of season:
        <div>${response}</div>
        </div>`;
    },
    async handleThemeSelected(theme) {
      try {
        this.addTheme = false;
        this.themeLoader = true;
        await weeklyMenus.setTheme(this.filter.weeklyMenu.id, theme.id);
        this.addedTheme = theme;
      }
      catch (error) {
        console.error(error);
      }
      finally {
        this.themeLoader = false;
      }
    },
    async handleRemoveTheme() {
      try {
        if (!confirm('Are you sure you want to remove this theme?')) {
          return;
        }
        this.themeLoader = true;
        await weeklyMenus.unsetTheme(this.filter.weeklyMenu.id);
        this.addedTheme = null;
      }
      catch (error) {
        console.error(error);
      }
      finally {
        this.themeLoader = false;
      }
    },
    addSegmentEvents() {
      const trackList = (list, event) => {
        return list.forEach(it => {
          this.track({
            event,
            properties: {
              week_id: this.week.id,
              week_start_date: this.week.startDate,
              recipe_id: it.id,
              recipe_name: it.name,
              slot_number: it.slotNumber,
              features: it.features.map(it => it.name).join(', '),
              is_themed: it.theme_id !== null,
              tags: it.tags.map(it => it.name).join(', '),
              recommended_by: it.by_ml ? 'ML' : 'User',
            },
          });
        });
      };

      trackList(this.deletedRecipes, 'Recipe Deleted');
      trackList(this.addedRecipes, 'Recipe Added');
    },
    async getCuisineList() {
      this.loadingCuisineCount = true;
      this.cuisineList = [];
      try {
        const {cuisine_count} = await weeklyMenus.cuisineCount(
          this.filter.weeklyMenu.id,
        );

        Object.keys(cuisine_count).forEach((it, i) => {
          this.cuisineList.push({
            name: `${it}: ${cuisine_count[it]}`,
          });
        });
      }
      catch (error) {
        captureException(error);
        console.error(error);
      }
      finally {
        this.loadingCuisineCount = false;
      }
    },
    disabledSlotsForPublishedWeek(recipe) {
      // check if the off-peak recipe to be disabled for published menu
      return this.week.status === 'published' &&
      recipe.slotNumber > 24 &&
      !('name' in recipe);
    },
    lastConceptMenuStartDate(recipe) {
      // get the last concept menu start date for the recipe (previous to the current week)
      const filteredDates = recipe.concept_menu_start_dates
        ?.filter(date => date < this.week.startDate)
        .sort((a, b) => new Date(b) - new Date(a));

      if (!filteredDates || filteredDates.length === 0) {
        return 'N/A';
      }

      return moment(filteredDates[0]).format('MMM DD, YYYY');
    },
  },
};
</script>

<style scoped>
.modal-backdrop {
  opacity: 0.8;
}

::v-deep .modal-add-recipe-form .modal-dialog {
  max-width: 90%;
}

::v-deep .modal-add-theme .modal-body {
  padding: 0;
}

::v-deep .modal-add-theme .modal-body .card-body {
  max-height: 80vh;
  overflow-y: auto;
}

::v-deep .modal-theme-not-ready .modal-header {
  border: 0;
  padding-top: 40px;
  padding-bottom: 16px;
  justify-content: center;
  align-items: center;
}

::v-deep .modal-theme-not-ready .modal-header .close {
  position: absolute;
  right: 16px;
  top: 16px;
}

::v-deep .modal-theme-not-ready .modal-body {
  padding: 0 64px;
  padding-bottom: 16px;
}

::v-deep .modal-theme-not-ready .modal-footer {
  justify-content: center;
  align-items: center;
  border: 0;
  padding-top: 0;
}

.swap-highlight {
  display: flex;
  align-items: center;
  background-color: #ec6e58;
}

.swap-indicator {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.swap-action {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  color: #467fcf;
  cursor: pointer;
}

.p-text {
  margin: 0;
}

.available-swap-text:hover {
  text-decoration: underline;
}

.plus-icon {
  font-size: 16px;
}

.swap-label {
  padding: 4px 6px;

  background: #e8ecef;
  border-radius: 4px;
}

.container {
  max-width: 1440px;
  padding-left: 50px;
  padding-right: 50px;
}

.recipe-tile,
.empty-card {
  width: 100%;
  grid-column: auto/span 2;
  border-radius: 20px;
  max-width: 320px;
  box-sizing: content-box;
}

.empty-card-header img,
.empty-card img {
  height: 64px;
}

.disable-card {
  opacity: 0.6;
  cursor: not-allowed;
  box-shadow: none;
}

.disable-card>div {
  pointer-events: none;
}

.swap-arrow {
  width: 20px;
  height: 20px;
  margin-right: 4px;
  fill: #ffffff;
}

.recipe-tile-list {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 20px;
  gap: 20px;
  grid-row-gap: 24px;
  row-gap: 24px;
}

.recipe-card {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  max-width: none;
  grid-gap: 16px;
  gap: 16px;
  padding-top: 0;
  padding-bottom: 0;
  flex: 1;
}

.recipe-header {
  min-width: 180px;
  width: 100%;
  height: 228px;
  overflow: hidden;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
}

.recipe-header .recipe-header-text span + span::before {
  content: ' +';
}

.actions {
  position: absolute;
  top: 180px;
  left: 0px;
}

.status {
  position: absolute;
  top: 176px;
  right: 0px;
}

.tag {
  font-weight: 600;
}

.actions .tag {
  border-bottom-left-radius: 0px;
  font-size: 1.5rem;
  font-weight: 600;
}

.recipe-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.recipe-details {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  flex-grow: 1;
  height: auto;
  justify-content: space-between;
  padding-bottom: 1rem;
}

.recipe-details-preview {
  min-height: 160px;
  padding-bottom: 0;
}

.recipe-details .title {
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
}

.recipe-details h6 {
  font-size: 20px;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.01em;
  margin-bottom: 0;
}

.recipe-details h5 {
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.01em;
  margin-bottom: 0;
  margin-top: 2px;
  color: #92959c;
}

.recipe-details .feature-icon {
  width: 20px;
  height: 20px;
}

.feature-icon.icon-long {
  width: auto;
}

.separator {
  position: relative;
  padding-inline: 8px;
}

.separator:not(:first-child)::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 0.5px;
  margin-block: 3px;
  background-color: #92959c;
}

.recipe-footer {
  height: 84px;
  width: 100%;
}

.recipe-footer .add-button {
  width: 100%;
  text-align: center;
  border: 1px dashed #ddd;
  background-color: transparent;
  min-width: 120px;
  min-height: 40px;
  padding: 0px 8px;
  font-weight: 700;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.btn-delete {
  width: 42px;
  height: 42px;
  border-radius: 30px;
}

.board-card {
  width: 240px;
  border-radius: 12px;
  padding: 8px 16px;
}

.board-card-title {
  font-size: 16px;
  font-weight: 600;
}

.board-card .board-card-value {
  display: grid;
  place-items: center;
  padding-top: 4px;
  font-size: 32px;
  font-weight: bold;
}

.link-btn-recheck {
  text-decoration: underline;
  cursor: pointer;
}
</style>
